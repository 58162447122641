export default function useRouterNormalize() {
  const normalizeQuery = (key: string, defaultValue = ''): string => {
    const route = useRoute();
    const input = route.query[key] ?? null;
    if (input) {
      if (Array.isArray(input)) {
        return input[0] as string;
      }
      return input as string;
    }
    return defaultValue;
  };

  const hasQuery = (key: string) => {
    const route = useRoute();
    const input = route.query[key] ?? null;
    if (input) {
      return true;
    }
    return false;
  };

  return {
    normalizeQuery,
    hasQuery,
  };
}
